import React from "react";
import { useInput, required } from "react-admin";
import { useForm } from "react-final-form";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from "react-google-places-autocomplete";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import capitalize from "@material-ui/core/utils/capitalize";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
    //   position: "absolute",
    //   zIndex: 10000000,
      backgroundColor: "red", //theme.palette.background.paper,
      border: `1px solid ${theme.palette.border}`,
      marginTop: theme.spacing(0.1),
      borderRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: "inherit"
    },
    suggestion: {
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.background.dark
      }
    },
    suggestionActive: {
      backgroundColor: theme.palette.background.default
    },
    label: {
      transform: "translate(-8px, -14px) scale(.75)",
      backgroundColor: theme.palette.background.paper,
      width: "fit-content",
      paddingLeft: 6,
      paddingRight: 6
    }
  })
);
const LocaleInput = props => {
  const form = useForm();
  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired
  } = useInput(props);
  const { label, variant } = props;
  const classes = useStyles();
  return (
  

    <GooglePlacesAutocomplete
    apiKey="AIzaSyBSxt_kdGtffussM99KLn7UclJmab5sDBI"
    //   onSelect={({ description, ...rest }) => {
    //     geocodeByPlaceId(rest.place_id)
    //       .then(results => getLatLng(results[0]))
    //       .then(res => {
    //         const state = name.split("_")[0];
    //         form.change(`${state}_ltd`, res.lat);
    //         form.change(`${state}_lng`, res.lng);
    //       })
    //       .catch(err => console.error(err));
    //     return onChange(description);
    //   }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["us"]
        }
      }}
      idPrefix={name}
      initialValue={value}
      name={name}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          label={label}
          variant={variant}
          error={!!(touched && error)}
          helperText={touched && error}
          size="small"
          fullWidth
        />
      )}
      renderSuggestions={(active, suggestions, onSelectSuggestion) => (
        <Paper variant="outlined" className={classes.container}>
          <List>
            {suggestions.map(suggestion => (
              <ListItem
                key={suggestion.place_id}
                button
                onClick={event => onSelectSuggestion(suggestion, event)}
                style={{ borderRadius: 0 }}
              >
                <ListItemText primary={suggestion.description} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    />
  );
};
export default ({ source, label, variant }) => (
  <LocaleInput
    label={label || capitalize(source.split("_").join(" "))}
    source={`${source}`}
    variant={variant}
    required={required()}
  />
);
