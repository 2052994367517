// in App.js
// import { Amplify } from "@aws-amplify/core";
import React from "react";
import { Resource } from "react-admin";
import { AmplifyAdmin } from "react-admin-amplify";
// import awsExports from "./aws-exports";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import { HouseList, HouseEdit, HouseCreate, HouseShow, HouseEditPrice,HouseEditOriginal } from './houses';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { createMuiTheme } from '@material-ui/core/styles';
import PostIcon from '@material-ui/icons/Home';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';




// #### PROD
Amplify.configure({
  aws_appsync_region: "us-west-2",
  aws_appsync_graphqlEndpoint: "https://hxdsiclbm5d7xk4jzbhnyyzmiy.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-c37rbbt5ibgpzlmbzxi4smw4ke",
  Auth: {
      identityPoolId: "us-west-2:6800870d-a1a8-4853-a8bb-2bcb3dc7dc08", //REQUIRED - Amazon Cognito Identity Pool ID
      region: "us-west-2", // REQUIRED - Amazon Cognito Region
      userPoolId: "us-west-2_plnBUiqwX", //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: "3fkddv6h0q9s6hai3duu0d2tel", //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
      AWSS3: {
          bucket: "fintoraws23d7ea886a05a4394aa3137f1733ff21d114908-main", //REQUIRED -  Amazon S3 bucket name
          region: "us-west-2", //OPTIONAL -  Amazon service region
      }
  }
});


// // #### STAGE
// Amplify.configure({
//     aws_appsync_region: "us-west-2",
//     aws_appsync_graphqlEndpoint: "https://6hn3p2oxqzamvglmhyzt7n2izy.appsync-api.us-west-2.amazonaws.com/graphql",
//     aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//     aws_appsync_apiKey: "da2-yutvekts2jbmjjqe45tmrg5tzq",
//     Auth: {
//         identityPoolId: "us-west-2:5d9c1315-712e-4115-b45a-18e991119849", //REQUIRED - Amazon Cognito Identity Pool ID
//         region: "us-west-2", // REQUIRED - Amazon Cognito Region
//         userPoolId: "us-west-2_LHn2NMzWf", //OPTIONAL - Amazon Cognito User Pool ID
//         userPoolWebClientId: "13eti0keia2emf0h3rkf27pagq", //OPTIONAL - Amazon Cognito Web Client ID
//     },
//     Storage: {
//         AWSS3: {
//             bucket: "fintoraws23d7ea886a05a4394aa3137f1733ff21d174959-stage", //REQUIRED -  Amazon S3 bucket name
//             region: "us-west-2", //OPTIONAL -  Amazon service region
//         }
//     }
// });


// #### devii
// Amplify.configure({
//   aws_appsync_region: "us-west-2",
//   aws_appsync_graphqlEndpoint: "https://4mnxwtlbzrbc5bgpc2iu2fsysi.appsync-api.us-west-2.amazonaws.com/graphql",
//   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//   aws_appsync_apiKey: "da2-zj43bqbwnvbx5f2bs7bsxdjgkq",

//   Auth: {
//       identityPoolId: "us-west-2:49ee8b66-7200-48b5-a3fd-96b3536f3d05", //REQUIRED - Amazon Cognito Identity Pool ID
//       region: "us-west-2", // REQUIRED - Amazon Cognito Region
//       userPoolId: "us-west-2_s6PyWrwA9", //OPTIONAL - Amazon Cognito User Pool ID
//       userPoolWebClientId: "1ngikraufqe4i76ndlqjc331cp", //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//       AWSS3: {
//           bucket: "fintoraws23d7ea886a05a4394aa3137f1733ff21d121852-devii", //REQUIRED -  Amazon S3 bucket name
//           region: "us-west-2", //OPTIONAL -  Amazon service region
//       }
//   }
// });


// Amplify.configure(awsExports);

function App() {

  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#24cc74',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#0066ff',
        main: '#000000',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      },
      // type: 'dark', // Switching the dark mode on is a single property value change.
      type: 'light'
    },
  });

  
  return (
    <AmplifyAdmin
      operations={{ queries, mutations }}
      theme={theme}
      options={{
        authGroups: ["admins","Admins"],
      }}
    >
<Resource
          name="Houses"
          options={{label: "Dividends"}}
          list={HouseList}
          show={HouseShow}
          edit={HouseEdit}
          icon={LocalAtmIcon}
        />

    </AmplifyAdmin>
  );
}

export default App;