/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateHouseShare = /* GraphQL */ `
  mutation UpdateHouseShare(
    $id: ID!
    $ShareAvailable: Int
    $HouseStatus: String
  ) {
    UpdateHouseShare(
      id: $id
      ShareAvailable: $ShareAvailable
      HouseStatus: $HouseStatus
    ) {
      id
      createdAt
      updatedAt
      photo
      photoS3 {
        bucket
        region
        key
      }
      fileS3 {
        bucket
        region
        key
      }
      Low1Y
      State
      city
      recom
      title
      place
      symbolID
      description
      ShareAvailable
      newBuilding
      Address
      price
      priceLive
      Net
      longitude
      PrimaryOfferID
      SecondaryOfferID
      IPOENDDATE
      NextTrading
      cityAddress
      High1Y
      commodity
      vol
      growth
      trend
      onboardFA
      onboardRE
      onboardMA
      onboardENG
      LastPriceUpdate
      HouseStatus
      RentDiv
      NextDiv
      numberOfInvstors
      mercuryAccountID
      TradingWindow
      ulLink
      latitude
      earlyAccess
      haltTrading
      CAP
      fullprice
      News {
        id
        url
        imageUrl
        date
        title
        source
      }
      Neighborhood {
        PricePerSqft
        AverageSale
        Name
        CommuteRate
        SchoolRate
        JobRate
        WalkRate
        WeatherRate
        Description
        MonthlyRent
        NGRate
        jobGrowth
        Unemployment
        RentGrowth
        DaysInMarket
        PopulationGrowth
      }
      HouseChart {
        y
        x
      }
      ShareChartTicks {
        y
        x
      }
      OpenChartTicks {
        y
        x
      }
      Documents {
        Doc1
        Doc2
        Doc3
        Doc4
        Doc5
      }
      Details {
        Type
        Lot
        Heating
        YearB
        Bathroom
        Parking
        Cooling
        YearRenov
        Bedroom
        House
      }
      ShareChart {
        GainLable
        GainTrend
        Gain
        Rent
        RentP
        GainP
      }
      nextRentPerShare
      nextRentDate
      photos
      photosS3 {
        bucket
        region
        key
      }
      Highlights {
        text
        title
        id
      }
      Financials {
        ProfitAnnual
        PricePsqft
        ExpencesAnnual
        LoanValue
        Bonus
        closing
        Renovation
        Brokerage
        RentAnnual
        NumShares
        DebtsAnnual
        LoanTovalue
        LoanInterstRate
        CashReserve
      }
    }
  }
`;
export const addExpoToken = /* GraphQL */ `
  mutation AddExpoToken($token: String!) {
    addExpoToken(token: $token) {
      id
      token
      playerUsername
      createdAt
      updatedAt
    }
  }
`;
export const updatePending = /* GraphQL */ `
  mutation UpdatePending(
    $sub: String!
    $pendingInvites: [String]
    $availableInvitations: Int
  ) {
    updatePending(
      sub: $sub
      pendingInvites: $pendingInvites
      availableInvitations: $availableInvitations
    ) {
      email
      sub
      username
      firstName
      notification
      enotification
      theme
      addressCity
      faceID
      addressStreet
      streetAddress
      unitAddress
      createdAt
      updatedAt
      city
      state
      zipcode
      country
      phoneNumber
      displayName
      AvatarKey
      AvatarURL
      ChatToken
      lastName
      appTheme
      badgeCount
      updateType
      AtsUserId
      ChatGuide
      AtsApplicationId
      AtsAccountId
      AtsBankAccountUID
      LegalFirstName
      LegalLastName
      GiftStatus
      InvestmentLimit
      cash {
        amount
        power
        withdraw
        reserved
        transit
        primary
      }
      tutorial1
      tutorial2
      tutorial12
      verification
      verificationProgress
      usertype
      type
      notificationBadge
      notify
      availableInvitations
      acceptedInvites
      pendingInvites
      expiredInvites
      watchList
      mercuryID
      invitedby
      bankConnected
      tokens {
        items {
          id
          token
          playerUsername
          createdAt
          updatedAt
        }
        nextToken
      }
      ownerships {
        items {
          userID
          assetID
          totalShares
          city
          title
          ownershipStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      PlaidToken {
        items {
          id
          BankAccountId
          ITEM_ID
          ACCESS_TOKEN
          processorToken
          createdAt
          updatedAt
        }
        nextToken
      }
      contactlist {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
      InitOrders {
        items {
          id
          commodity
          totalfee
          totalAmount
          walletAmount
          shares
          sharePrice
          HouseId
          UserID
          AtsOrderID
          type
          orderStatus
          date
          createdAt
          updatedAt
          escrowPaymentID
          dwTransferID
        }
        nextToken
      }
      DividendPayments {
        items {
          id
          userId
          assetId
          totalAmount
          paytype
          shares
          payPeriod
          payStatus
          transactionMercuryID
          payDate
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          type
          title
          description
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      TradingOrders {
        items {
          id
          AtsOrderID
          AtsExecutionId
          AtsParentOrderId
          AtsTransactionId
          commodity
          totalfee
          totalAmount
          shares
          sharePrice
          type
          orderStatus
          symbolId
          symbol
          sideId
          AtsAccountId
          AtsUserId
          AtsTradeId
          AtsTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      Transactions {
        items {
          amount
          AtsOrderID
          bankTransactionUID
          createdAt
          updatedAt
          paymentStatus
          type
          primaryOrder
        }
        nextToken
      }
      SubsLists {
        items {
          id
          listID
          userID
          subscription
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const submitOrder = /* GraphQL */ `
  mutation SubmitOrder($order: ordersubmit!) {
    SubmitOrder(order: $order)
  }
`;
export const cancelOrder = /* GraphQL */ `
  mutation CancelOrder($info: cancelsubmit!) {
    CancelOrder(info: $info)
  }
`;
export const submitLimitOrder = /* GraphQL */ `
  mutation SubmitLimitOrder($order: ordersubmit!) {
    SubmitLimitOrder(order: $order)
  }
`;
export const submitContactForm = /* GraphQL */ `
  mutation SubmitContactForm($contact: contactsubmit!) {
    SubmitContactForm(contact: $contact)
  }
`;
export const submitSellYourHouse = /* GraphQL */ `
  mutation SubmitSellYourHouse($contact: sellHouseSubmit!) {
    SubmitSellYourHouse(contact: $contact)
  }
`;
export const askTokenPlaid = /* GraphQL */ `
  mutation AskTokenPlaid($sub: String) {
    AskTokenPlaid(sub: $sub)
  }
`;
export const askTokenPlaidAndroid = /* GraphQL */ `
  mutation AskTokenPlaidAndroid($sub: String) {
    AskTokenPlaidAndroid(sub: $sub)
  }
`;
export const askChatToken = /* GraphQL */ `
  mutation AskChatToken($sub: String) {
    AskChatToken(sub: $sub)
  }
`;
export const sendTokenPlaid = /* GraphQL */ `
  mutation SendTokenPlaid($plaid: tokensubmit!) {
    SendTokenPlaid(plaid: $plaid)
  }
`;
export const submitCashTransfer = /* GraphQL */ `
  mutation SubmitCashTransfer($cashorder: cashordersubmit!) {
    SubmitCashTransfer(cashorder: $cashorder)
  }
`;
export const redeemDividend = /* GraphQL */ `
  mutation RedeemDividend($info: submitInfo) {
    RedeemDividend(info: $info)
  }
`;
export const redeemAll = /* GraphQL */ `
  mutation RedeemAll($info: submitInfoAll) {
    RedeemAll(info: $info)
  }
`;
export const aTSEmailUpdate = /* GraphQL */ `
  mutation ATSEmailUpdate($email: String) {
    ATSEmailUpdate(email: $email)
  }
`;
export const submitVerification = /* GraphQL */ `
  mutation SubmitVerification($UserInfo: Verifysubmit!) {
    SubmitVerification(UserInfo: $UserInfo)
  }
`;
export const atsUser = /* GraphQL */ `
  mutation AtsUser($UserInfo: Verifysubmit!) {
    AtsUser(UserInfo: $UserInfo)
  }
`;
export const inviteFriends = /* GraphQL */ `
  mutation InviteFriends($digits: String!) {
    InviteFriends(digits: $digits)
  }
`;
export const syncContacts = /* GraphQL */ `
  mutation SyncContacts($newContacts: [ContactInput]) {
    SyncContacts(newContacts: $newContacts)
  }
`;
export const inviteWaitlist = /* GraphQL */ `
  mutation InviteWaitlist($digits: String!) {
    InviteWaitlist(digits: $digits)
  }
`;
export const sendNotification = /* GraphQL */ `
  mutation SendNotification($notifInput: notifSubmit) {
    SendNotification(notifInput: $notifInput)
  }
`;
export const kycMakeUser = /* GraphQL */ `
  mutation KycMakeUser($name: VerifyName!) {
    KycMakeUser(name: $name)
  }
`;
export const kycInfoSubmit = /* GraphQL */ `
  mutation KycInfoSubmit($UserInfo: Verifysubmit!) {
    KycInfoSubmit(UserInfo: $UserInfo)
  }
`;
export const kycDocuments = /* GraphQL */ `
  mutation KycDocuments($IdType: String) {
    KycDocuments(IdType: $IdType)
  }
`;
export const getRank = /* GraphQL */ `
  mutation GetRank($email: String) {
    GetRank(email: $email)
  }
`;
export const kycSubmission = /* GraphQL */ `
  mutation KycSubmission($limit: Float) {
    KycSubmission(limit: $limit)
  }
`;
export const sendListNotification = /* GraphQL */ `
  mutation SendListNotification($notifInput: notifListSubmit) {
    SendListNotification(notifInput: $notifInput)
  }
`;
export const plaidProcessToken = /* GraphQL */ `
  mutation PlaidProcessToken($user: String) {
    plaidProcessToken(user: $user)
  }
`;
export const checkPendingInvites = /* GraphQL */ `
  mutation CheckPendingInvites($list: [String]) {
    CheckPendingInvites(list: $list)
  }
`;
export const createExpoToken = /* GraphQL */ `
  mutation CreateExpoToken(
    $input: CreateExpoTokenInput!
    $condition: ModelExpoTokenConditionInput
  ) {
    createExpoToken(input: $input, condition: $condition) {
      id
      token
      playerUsername
      createdAt
      updatedAt
    }
  }
`;
export const updateExpoToken = /* GraphQL */ `
  mutation UpdateExpoToken(
    $input: UpdateExpoTokenInput!
    $condition: ModelExpoTokenConditionInput
  ) {
    updateExpoToken(input: $input, condition: $condition) {
      id
      token
      playerUsername
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpoToken = /* GraphQL */ `
  mutation DeleteExpoToken(
    $input: DeleteExpoTokenInput!
    $condition: ModelExpoTokenConditionInput
  ) {
    deleteExpoToken(input: $input, condition: $condition) {
      id
      token
      playerUsername
      createdAt
      updatedAt
    }
  }
`;
export const createExpoTicketsObject = /* GraphQL */ `
  mutation CreateExpoTicketsObject(
    $input: CreateExpoTicketsObjectInput!
    $condition: ModelExpoTicketsObjectConditionInput
  ) {
    createExpoTicketsObject(input: $input, condition: $condition) {
      id
      tickets
      createdAt
      updatedAt
    }
  }
`;
export const updateExpoTicketsObject = /* GraphQL */ `
  mutation UpdateExpoTicketsObject(
    $input: UpdateExpoTicketsObjectInput!
    $condition: ModelExpoTicketsObjectConditionInput
  ) {
    updateExpoTicketsObject(input: $input, condition: $condition) {
      id
      tickets
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpoTicketsObject = /* GraphQL */ `
  mutation DeleteExpoTicketsObject(
    $input: DeleteExpoTicketsObjectInput!
    $condition: ModelExpoTicketsObjectConditionInput
  ) {
    deleteExpoTicketsObject(input: $input, condition: $condition) {
      id
      tickets
      createdAt
      updatedAt
    }
  }
`;
export const createOwnership = /* GraphQL */ `
  mutation CreateOwnership(
    $input: CreateOwnershipInput!
    $condition: ModelownershipConditionInput
  ) {
    createOwnership(input: $input, condition: $condition) {
      userID
      assetID
      totalShares
      city
      title
      ownershipStatus
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const updateOwnership = /* GraphQL */ `
  mutation UpdateOwnership(
    $input: UpdateOwnershipInput!
    $condition: ModelownershipConditionInput
  ) {
    updateOwnership(input: $input, condition: $condition) {
      userID
      assetID
      totalShares
      city
      title
      ownershipStatus
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const deleteOwnership = /* GraphQL */ `
  mutation DeleteOwnership(
    $input: DeleteOwnershipInput!
    $condition: ModelownershipConditionInput
  ) {
    deleteOwnership(input: $input, condition: $condition) {
      userID
      assetID
      totalShares
      city
      title
      ownershipStatus
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const createGift = /* GraphQL */ `
  mutation CreateGift(
    $input: CreateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    createGift(input: $input, condition: $condition) {
      id
      sub
      GiftReason
      GiftType
      GiftStatus
      NumberOfShares
      AssetTicket
      TriggerDate
      createdAt
      updatedAt
    }
  }
`;
export const updateGift = /* GraphQL */ `
  mutation UpdateGift(
    $input: UpdateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    updateGift(input: $input, condition: $condition) {
      id
      sub
      GiftReason
      GiftType
      GiftStatus
      NumberOfShares
      AssetTicket
      TriggerDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteGift = /* GraphQL */ `
  mutation DeleteGift(
    $input: DeleteGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    deleteGift(input: $input, condition: $condition) {
      id
      sub
      GiftReason
      GiftType
      GiftStatus
      NumberOfShares
      AssetTicket
      TriggerDate
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModeluserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      sub
      username
      firstName
      notification
      enotification
      theme
      addressCity
      faceID
      addressStreet
      streetAddress
      unitAddress
      createdAt
      updatedAt
      city
      state
      zipcode
      country
      phoneNumber
      displayName
      AvatarKey
      AvatarURL
      ChatToken
      lastName
      appTheme
      badgeCount
      updateType
      AtsUserId
      ChatGuide
      AtsApplicationId
      AtsAccountId
      AtsBankAccountUID
      LegalFirstName
      LegalLastName
      GiftStatus
      InvestmentLimit
      cash {
        amount
        power
        withdraw
        reserved
        transit
        primary
      }
      tutorial1
      tutorial2
      tutorial12
      verification
      verificationProgress
      usertype
      type
      notificationBadge
      notify
      availableInvitations
      acceptedInvites
      pendingInvites
      expiredInvites
      watchList
      mercuryID
      invitedby
      bankConnected
      tokens {
        items {
          id
          token
          playerUsername
          createdAt
          updatedAt
        }
        nextToken
      }
      ownerships {
        items {
          userID
          assetID
          totalShares
          city
          title
          ownershipStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      PlaidToken {
        items {
          id
          BankAccountId
          ITEM_ID
          ACCESS_TOKEN
          processorToken
          createdAt
          updatedAt
        }
        nextToken
      }
      contactlist {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
      InitOrders {
        items {
          id
          commodity
          totalfee
          totalAmount
          walletAmount
          shares
          sharePrice
          HouseId
          UserID
          AtsOrderID
          type
          orderStatus
          date
          createdAt
          updatedAt
          escrowPaymentID
          dwTransferID
        }
        nextToken
      }
      DividendPayments {
        items {
          id
          userId
          assetId
          totalAmount
          paytype
          shares
          payPeriod
          payStatus
          transactionMercuryID
          payDate
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          type
          title
          description
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      TradingOrders {
        items {
          id
          AtsOrderID
          AtsExecutionId
          AtsParentOrderId
          AtsTransactionId
          commodity
          totalfee
          totalAmount
          shares
          sharePrice
          type
          orderStatus
          symbolId
          symbol
          sideId
          AtsAccountId
          AtsUserId
          AtsTradeId
          AtsTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      Transactions {
        items {
          amount
          AtsOrderID
          bankTransactionUID
          createdAt
          updatedAt
          paymentStatus
          type
          primaryOrder
        }
        nextToken
      }
      SubsLists {
        items {
          id
          listID
          userID
          subscription
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModeluserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      sub
      username
      firstName
      notification
      enotification
      theme
      addressCity
      faceID
      addressStreet
      streetAddress
      unitAddress
      createdAt
      updatedAt
      city
      state
      zipcode
      country
      phoneNumber
      displayName
      AvatarKey
      AvatarURL
      ChatToken
      lastName
      appTheme
      badgeCount
      updateType
      AtsUserId
      ChatGuide
      AtsApplicationId
      AtsAccountId
      AtsBankAccountUID
      LegalFirstName
      LegalLastName
      GiftStatus
      InvestmentLimit
      cash {
        amount
        power
        withdraw
        reserved
        transit
        primary
      }
      tutorial1
      tutorial2
      tutorial12
      verification
      verificationProgress
      usertype
      type
      notificationBadge
      notify
      availableInvitations
      acceptedInvites
      pendingInvites
      expiredInvites
      watchList
      mercuryID
      invitedby
      bankConnected
      tokens {
        items {
          id
          token
          playerUsername
          createdAt
          updatedAt
        }
        nextToken
      }
      ownerships {
        items {
          userID
          assetID
          totalShares
          city
          title
          ownershipStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      PlaidToken {
        items {
          id
          BankAccountId
          ITEM_ID
          ACCESS_TOKEN
          processorToken
          createdAt
          updatedAt
        }
        nextToken
      }
      contactlist {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
      InitOrders {
        items {
          id
          commodity
          totalfee
          totalAmount
          walletAmount
          shares
          sharePrice
          HouseId
          UserID
          AtsOrderID
          type
          orderStatus
          date
          createdAt
          updatedAt
          escrowPaymentID
          dwTransferID
        }
        nextToken
      }
      DividendPayments {
        items {
          id
          userId
          assetId
          totalAmount
          paytype
          shares
          payPeriod
          payStatus
          transactionMercuryID
          payDate
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          type
          title
          description
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      TradingOrders {
        items {
          id
          AtsOrderID
          AtsExecutionId
          AtsParentOrderId
          AtsTransactionId
          commodity
          totalfee
          totalAmount
          shares
          sharePrice
          type
          orderStatus
          symbolId
          symbol
          sideId
          AtsAccountId
          AtsUserId
          AtsTradeId
          AtsTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      Transactions {
        items {
          amount
          AtsOrderID
          bankTransactionUID
          createdAt
          updatedAt
          paymentStatus
          type
          primaryOrder
        }
        nextToken
      }
      SubsLists {
        items {
          id
          listID
          userID
          subscription
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModeluserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      sub
      username
      firstName
      notification
      enotification
      theme
      addressCity
      faceID
      addressStreet
      streetAddress
      unitAddress
      createdAt
      updatedAt
      city
      state
      zipcode
      country
      phoneNumber
      displayName
      AvatarKey
      AvatarURL
      ChatToken
      lastName
      appTheme
      badgeCount
      updateType
      AtsUserId
      ChatGuide
      AtsApplicationId
      AtsAccountId
      AtsBankAccountUID
      LegalFirstName
      LegalLastName
      GiftStatus
      InvestmentLimit
      cash {
        amount
        power
        withdraw
        reserved
        transit
        primary
      }
      tutorial1
      tutorial2
      tutorial12
      verification
      verificationProgress
      usertype
      type
      notificationBadge
      notify
      availableInvitations
      acceptedInvites
      pendingInvites
      expiredInvites
      watchList
      mercuryID
      invitedby
      bankConnected
      tokens {
        items {
          id
          token
          playerUsername
          createdAt
          updatedAt
        }
        nextToken
      }
      ownerships {
        items {
          userID
          assetID
          totalShares
          city
          title
          ownershipStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      PlaidToken {
        items {
          id
          BankAccountId
          ITEM_ID
          ACCESS_TOKEN
          processorToken
          createdAt
          updatedAt
        }
        nextToken
      }
      contactlist {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
      InitOrders {
        items {
          id
          commodity
          totalfee
          totalAmount
          walletAmount
          shares
          sharePrice
          HouseId
          UserID
          AtsOrderID
          type
          orderStatus
          date
          createdAt
          updatedAt
          escrowPaymentID
          dwTransferID
        }
        nextToken
      }
      DividendPayments {
        items {
          id
          userId
          assetId
          totalAmount
          paytype
          shares
          payPeriod
          payStatus
          transactionMercuryID
          payDate
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          type
          title
          description
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      TradingOrders {
        items {
          id
          AtsOrderID
          AtsExecutionId
          AtsParentOrderId
          AtsTransactionId
          commodity
          totalfee
          totalAmount
          shares
          sharePrice
          type
          orderStatus
          symbolId
          symbol
          sideId
          AtsAccountId
          AtsUserId
          AtsTradeId
          AtsTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      Transactions {
        items {
          amount
          AtsOrderID
          bankTransactionUID
          createdAt
          updatedAt
          paymentStatus
          type
          primaryOrder
        }
        nextToken
      }
      SubsLists {
        items {
          id
          listID
          userID
          subscription
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createContactUS = /* GraphQL */ `
  mutation CreateContactUS(
    $input: CreateContactUSInput!
    $condition: ModelContactUSConditionInput
  ) {
    createContactUS(input: $input, condition: $condition) {
      id
      email
      message
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const updateContactUS = /* GraphQL */ `
  mutation UpdateContactUS(
    $input: UpdateContactUSInput!
    $condition: ModelContactUSConditionInput
  ) {
    updateContactUS(input: $input, condition: $condition) {
      id
      email
      message
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const deleteContactUS = /* GraphQL */ `
  mutation DeleteContactUS(
    $input: DeleteContactUSInput!
    $condition: ModelContactUSConditionInput
  ) {
    deleteContactUS(input: $input, condition: $condition) {
      id
      email
      message
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const createSellYourHouse = /* GraphQL */ `
  mutation CreateSellYourHouse(
    $input: CreateSellYourHouseInput!
    $condition: ModelSellYourHouseConditionInput
  ) {
    createSellYourHouse(input: $input, condition: $condition) {
      id
      streetAddress
      unitAddress
      city
      state
      zipcode
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const updateSellYourHouse = /* GraphQL */ `
  mutation UpdateSellYourHouse(
    $input: UpdateSellYourHouseInput!
    $condition: ModelSellYourHouseConditionInput
  ) {
    updateSellYourHouse(input: $input, condition: $condition) {
      id
      streetAddress
      unitAddress
      city
      state
      zipcode
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const deleteSellYourHouse = /* GraphQL */ `
  mutation DeleteSellYourHouse(
    $input: DeleteSellYourHouseInput!
    $condition: ModelSellYourHouseConditionInput
  ) {
    deleteSellYourHouse(input: $input, condition: $condition) {
      id
      streetAddress
      unitAddress
      city
      state
      zipcode
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const createPlaidToken = /* GraphQL */ `
  mutation CreatePlaidToken(
    $input: CreatePlaidTokenInput!
    $condition: ModelPlaidTokenConditionInput
  ) {
    createPlaidToken(input: $input, condition: $condition) {
      id
      BankAccountId
      ITEM_ID
      ACCESS_TOKEN
      processorToken
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updatePlaidToken = /* GraphQL */ `
  mutation UpdatePlaidToken(
    $input: UpdatePlaidTokenInput!
    $condition: ModelPlaidTokenConditionInput
  ) {
    updatePlaidToken(input: $input, condition: $condition) {
      id
      BankAccountId
      ITEM_ID
      ACCESS_TOKEN
      processorToken
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deletePlaidToken = /* GraphQL */ `
  mutation DeletePlaidToken(
    $input: DeletePlaidTokenInput!
    $condition: ModelPlaidTokenConditionInput
  ) {
    deletePlaidToken(input: $input, condition: $condition) {
      id
      BankAccountId
      ITEM_ID
      ACCESS_TOKEN
      processorToken
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const createListToAll = /* GraphQL */ `
  mutation CreateListToAll(
    $input: CreateListToAllInput!
    $condition: ModelListToAllConditionInput
  ) {
    createListToAll(input: $input, condition: $condition) {
      id
      AllContactID
      UserID
      createdAt
      updatedAt
      User {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      AllContact {
        id
        firstName
        jobTitle
        company
        lastName
        middleName
        name
        status
        countryCode
        digits
        number
        city
        country
        isoCountryCode
        label
        postalCode
        region
        street
        createdAt
        updatedAt
        connections {
          nextToken
        }
      }
    }
  }
`;
export const updateListToAll = /* GraphQL */ `
  mutation UpdateListToAll(
    $input: UpdateListToAllInput!
    $condition: ModelListToAllConditionInput
  ) {
    updateListToAll(input: $input, condition: $condition) {
      id
      AllContactID
      UserID
      createdAt
      updatedAt
      User {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      AllContact {
        id
        firstName
        jobTitle
        company
        lastName
        middleName
        name
        status
        countryCode
        digits
        number
        city
        country
        isoCountryCode
        label
        postalCode
        region
        street
        createdAt
        updatedAt
        connections {
          nextToken
        }
      }
    }
  }
`;
export const deleteListToAll = /* GraphQL */ `
  mutation DeleteListToAll(
    $input: DeleteListToAllInput!
    $condition: ModelListToAllConditionInput
  ) {
    deleteListToAll(input: $input, condition: $condition) {
      id
      AllContactID
      UserID
      createdAt
      updatedAt
      User {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      AllContact {
        id
        firstName
        jobTitle
        company
        lastName
        middleName
        name
        status
        countryCode
        digits
        number
        city
        country
        isoCountryCode
        label
        postalCode
        region
        street
        createdAt
        updatedAt
        connections {
          nextToken
        }
      }
    }
  }
`;
export const createAllContact = /* GraphQL */ `
  mutation CreateAllContact(
    $input: CreateAllContactInput!
    $condition: ModelAllContactConditionInput
  ) {
    createAllContact(input: $input, condition: $condition) {
      id
      firstName
      jobTitle
      company
      lastName
      middleName
      name
      status
      countryCode
      digits
      number
      city
      country
      isoCountryCode
      label
      postalCode
      region
      street
      createdAt
      updatedAt
      connections {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAllContact = /* GraphQL */ `
  mutation UpdateAllContact(
    $input: UpdateAllContactInput!
    $condition: ModelAllContactConditionInput
  ) {
    updateAllContact(input: $input, condition: $condition) {
      id
      firstName
      jobTitle
      company
      lastName
      middleName
      name
      status
      countryCode
      digits
      number
      city
      country
      isoCountryCode
      label
      postalCode
      region
      street
      createdAt
      updatedAt
      connections {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAllContact = /* GraphQL */ `
  mutation DeleteAllContact(
    $input: DeleteAllContactInput!
    $condition: ModelAllContactConditionInput
  ) {
    deleteAllContact(input: $input, condition: $condition) {
      id
      firstName
      jobTitle
      company
      lastName
      middleName
      name
      status
      countryCode
      digits
      number
      city
      country
      isoCountryCode
      label
      postalCode
      region
      street
      createdAt
      updatedAt
      connections {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createInitOrder = /* GraphQL */ `
  mutation CreateInitOrder(
    $input: CreateInitOrderInput!
    $condition: ModelInitOrderConditionInput
  ) {
    createInitOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      totalAmount
      walletAmount
      shares
      sharePrice
      HouseId
      UserID
      AtsOrderID
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      escrowPaymentID
      dwTransferID
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const updateInitOrder = /* GraphQL */ `
  mutation UpdateInitOrder(
    $input: UpdateInitOrderInput!
    $condition: ModelInitOrderConditionInput
  ) {
    updateInitOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      totalAmount
      walletAmount
      shares
      sharePrice
      HouseId
      UserID
      AtsOrderID
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      escrowPaymentID
      dwTransferID
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const deleteInitOrder = /* GraphQL */ `
  mutation DeleteInitOrder(
    $input: DeleteInitOrderInput!
    $condition: ModelInitOrderConditionInput
  ) {
    deleteInitOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      totalAmount
      walletAmount
      shares
      sharePrice
      HouseId
      UserID
      AtsOrderID
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      escrowPaymentID
      dwTransferID
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const createDividendPayment = /* GraphQL */ `
  mutation CreateDividendPayment(
    $input: CreateDividendPaymentInput!
    $condition: ModelDividendPaymentConditionInput
  ) {
    createDividendPayment(input: $input, condition: $condition) {
      id
      userId
      assetId
      totalAmount
      paytype
      shares
      payPeriod
      payStatus
      transactionMercuryID
      payDate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const updateDividendPayment = /* GraphQL */ `
  mutation UpdateDividendPayment(
    $input: UpdateDividendPaymentInput!
    $condition: ModelDividendPaymentConditionInput
  ) {
    updateDividendPayment(input: $input, condition: $condition) {
      id
      userId
      assetId
      totalAmount
      paytype
      shares
      payPeriod
      payStatus
      transactionMercuryID
      payDate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const deleteDividendPayment = /* GraphQL */ `
  mutation DeleteDividendPayment(
    $input: DeleteDividendPaymentInput!
    $condition: ModelDividendPaymentConditionInput
  ) {
    deleteDividendPayment(input: $input, condition: $condition) {
      id
      userId
      assetId
      totalAmount
      paytype
      shares
      payPeriod
      payStatus
      transactionMercuryID
      payDate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelactivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      type
      title
      description
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelactivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      type
      title
      description
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelactivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      type
      title
      description
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const createOpenTradingOrder = /* GraphQL */ `
  mutation CreateOpenTradingOrder(
    $input: CreateOpenTradingOrderInput!
    $condition: ModelOpenTradingOrderConditionInput
  ) {
    createOpenTradingOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      HouseId
      totalAmount
      shares
      AtsOrderId
      filledQuantity
      containsPartialFills
      filledDate
      sharePrice
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const updateOpenTradingOrder = /* GraphQL */ `
  mutation UpdateOpenTradingOrder(
    $input: UpdateOpenTradingOrderInput!
    $condition: ModelOpenTradingOrderConditionInput
  ) {
    updateOpenTradingOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      HouseId
      totalAmount
      shares
      AtsOrderId
      filledQuantity
      containsPartialFills
      filledDate
      sharePrice
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const deleteOpenTradingOrder = /* GraphQL */ `
  mutation DeleteOpenTradingOrder(
    $input: DeleteOpenTradingOrderInput!
    $condition: ModelOpenTradingOrderConditionInput
  ) {
    deleteOpenTradingOrder(input: $input, condition: $condition) {
      id
      commodity
      totalfee
      HouseId
      totalAmount
      shares
      AtsOrderId
      filledQuantity
      containsPartialFills
      filledDate
      sharePrice
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const createTradingOrder = /* GraphQL */ `
  mutation CreateTradingOrder(
    $input: CreateTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    createTradingOrder(input: $input, condition: $condition) {
      id
      AtsOrderID
      AtsExecutionId
      AtsParentOrderId
      AtsTransactionId
      commodity
      totalfee
      totalAmount
      shares
      sharePrice
      type
      orderStatus
      symbolId
      symbol
      sideId
      AtsAccountId
      AtsUserId
      AtsTradeId
      AtsTimestamp
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const updateTradingOrder = /* GraphQL */ `
  mutation UpdateTradingOrder(
    $input: UpdateTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    updateTradingOrder(input: $input, condition: $condition) {
      id
      AtsOrderID
      AtsExecutionId
      AtsParentOrderId
      AtsTransactionId
      commodity
      totalfee
      totalAmount
      shares
      sharePrice
      type
      orderStatus
      symbolId
      symbol
      sideId
      AtsAccountId
      AtsUserId
      AtsTradeId
      AtsTimestamp
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const deleteTradingOrder = /* GraphQL */ `
  mutation DeleteTradingOrder(
    $input: DeleteTradingOrderInput!
    $condition: ModelTradingOrderConditionInput
  ) {
    deleteTradingOrder(input: $input, condition: $condition) {
      id
      AtsOrderID
      AtsExecutionId
      AtsParentOrderId
      AtsTransactionId
      commodity
      totalfee
      totalAmount
      shares
      sharePrice
      type
      orderStatus
      symbolId
      symbol
      sideId
      AtsAccountId
      AtsUserId
      AtsTradeId
      AtsTimestamp
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      amount
      AtsOrderID
      bankTransactionUID
      createdAt
      updatedAt
      paymentStatus
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      type
      primaryOrder
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      amount
      AtsOrderID
      bankTransactionUID
      createdAt
      updatedAt
      paymentStatus
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      type
      primaryOrder
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      amount
      AtsOrderID
      bankTransactionUID
      createdAt
      updatedAt
      paymentStatus
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      type
      primaryOrder
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      digits
      email
      invitationStatus
      acceptedInvitation
      StatusHistory {
        status
        time
      }
      firstApply
      lastInviter
      allInviters {
        inviter
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      digits
      email
      invitationStatus
      acceptedInvitation
      StatusHistory {
        status
        time
      }
      firstApply
      lastInviter
      allInviters {
        inviter
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      digits
      email
      invitationStatus
      acceptedInvitation
      StatusHistory {
        status
        time
      }
      firstApply
      lastInviter
      allInviters {
        inviter
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInvitedEmails = /* GraphQL */ `
  mutation CreateInvitedEmails(
    $input: CreateInvitedEmailsInput!
    $condition: ModelInvitedEmailsConditionInput
  ) {
    createInvitedEmails(input: $input, condition: $condition) {
      id
      referals
      applieTime
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitedEmails = /* GraphQL */ `
  mutation UpdateInvitedEmails(
    $input: UpdateInvitedEmailsInput!
    $condition: ModelInvitedEmailsConditionInput
  ) {
    updateInvitedEmails(input: $input, condition: $condition) {
      id
      referals
      applieTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitedEmails = /* GraphQL */ `
  mutation DeleteInvitedEmails(
    $input: DeleteInvitedEmailsInput!
    $condition: ModelInvitedEmailsConditionInput
  ) {
    deleteInvitedEmails(input: $input, condition: $condition) {
      id
      referals
      applieTime
      createdAt
      updatedAt
    }
  }
`;
export const createWaitingList = /* GraphQL */ `
  mutation CreateWaitingList(
    $input: CreateWaitingListInput!
    $condition: ModelWaitingListConditionInput
  ) {
    createWaitingList(input: $input, condition: $condition) {
      id
      digits
      email
      waitStatus
      allInviters
      sub
      createdAt
      updatedAt
    }
  }
`;
export const updateWaitingList = /* GraphQL */ `
  mutation UpdateWaitingList(
    $input: UpdateWaitingListInput!
    $condition: ModelWaitingListConditionInput
  ) {
    updateWaitingList(input: $input, condition: $condition) {
      id
      digits
      email
      waitStatus
      allInviters
      sub
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaitingList = /* GraphQL */ `
  mutation DeleteWaitingList(
    $input: DeleteWaitingListInput!
    $condition: ModelWaitingListConditionInput
  ) {
    deleteWaitingList(input: $input, condition: $condition) {
      id
      digits
      email
      waitStatus
      allInviters
      sub
      createdAt
      updatedAt
    }
  }
`;
export const createUserVerification = /* GraphQL */ `
  mutation CreateUserVerification(
    $input: CreateUserVerificationInput!
    $condition: ModelUserVerificationConditionInput
  ) {
    createUserVerification(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updateUserVerification = /* GraphQL */ `
  mutation UpdateUserVerification(
    $input: UpdateUserVerificationInput!
    $condition: ModelUserVerificationConditionInput
  ) {
    updateUserVerification(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserVerification = /* GraphQL */ `
  mutation DeleteUserVerification(
    $input: DeleteUserVerificationInput!
    $condition: ModelUserVerificationConditionInput
  ) {
    deleteUserVerification(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const createUserVerificationRaw = /* GraphQL */ `
  mutation CreateUserVerificationRaw(
    $input: CreateUserVerificationRawInput!
    $condition: ModelUserVerificationRawConditionInput
  ) {
    createUserVerificationRaw(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      place
      placeJ
      lat
      long
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      noFinra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updateUserVerificationRaw = /* GraphQL */ `
  mutation UpdateUserVerificationRaw(
    $input: UpdateUserVerificationRawInput!
    $condition: ModelUserVerificationRawConditionInput
  ) {
    updateUserVerificationRaw(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      place
      placeJ
      lat
      long
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      noFinra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserVerificationRaw = /* GraphQL */ `
  mutation DeleteUserVerificationRaw(
    $input: DeleteUserVerificationRawInput!
    $condition: ModelUserVerificationRawConditionInput
  ) {
    deleteUserVerificationRaw(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      place
      placeJ
      lat
      long
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      noFinra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const createAppConfig = /* GraphQL */ `
  mutation CreateAppConfig(
    $input: CreateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    createAppConfig(input: $input, condition: $condition) {
      id
      secondaryOpen
      forceUpdate
      dynamicLinkURL
      createdAt
      updatedAt
    }
  }
`;
export const updateAppConfig = /* GraphQL */ `
  mutation UpdateAppConfig(
    $input: UpdateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    updateAppConfig(input: $input, condition: $condition) {
      id
      secondaryOpen
      forceUpdate
      dynamicLinkURL
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppConfig = /* GraphQL */ `
  mutation DeleteAppConfig(
    $input: DeleteAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    deleteAppConfig(input: $input, condition: $condition) {
      id
      secondaryOpen
      forceUpdate
      dynamicLinkURL
      createdAt
      updatedAt
    }
  }
`;
export const createLearnTab = /* GraphQL */ `
  mutation CreateLearnTab(
    $input: CreateLearnTabInput!
    $condition: ModelLearnTabConditionInput
  ) {
    createLearnTab(input: $input, condition: $condition) {
      id
      cardNumber
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateLearnTab = /* GraphQL */ `
  mutation UpdateLearnTab(
    $input: UpdateLearnTabInput!
    $condition: ModelLearnTabConditionInput
  ) {
    updateLearnTab(input: $input, condition: $condition) {
      id
      cardNumber
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteLearnTab = /* GraphQL */ `
  mutation DeleteLearnTab(
    $input: DeleteLearnTabInput!
    $condition: ModelLearnTabConditionInput
  ) {
    deleteLearnTab(input: $input, condition: $condition) {
      id
      cardNumber
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const createNewsTab = /* GraphQL */ `
  mutation CreateNewsTab(
    $input: CreateNewsTabInput!
    $condition: ModelNewsTabConditionInput
  ) {
    createNewsTab(input: $input, condition: $condition) {
      id
      url
      imageSmallUrl
      photoSmallS3 {
        bucket
        region
        key
      }
      imageLargeUrl
      photoLargeS3 {
        bucket
        region
        key
      }
      videoUrl
      video
      headerNews
      date
      title
      source
      assetsTags {
        title
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsTab = /* GraphQL */ `
  mutation UpdateNewsTab(
    $input: UpdateNewsTabInput!
    $condition: ModelNewsTabConditionInput
  ) {
    updateNewsTab(input: $input, condition: $condition) {
      id
      url
      imageSmallUrl
      photoSmallS3 {
        bucket
        region
        key
      }
      imageLargeUrl
      photoLargeS3 {
        bucket
        region
        key
      }
      videoUrl
      video
      headerNews
      date
      title
      source
      assetsTags {
        title
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsTab = /* GraphQL */ `
  mutation DeleteNewsTab(
    $input: DeleteNewsTabInput!
    $condition: ModelNewsTabConditionInput
  ) {
    deleteNewsTab(input: $input, condition: $condition) {
      id
      url
      imageSmallUrl
      photoSmallS3 {
        bucket
        region
        key
      }
      imageLargeUrl
      photoLargeS3 {
        bucket
        region
        key
      }
      videoUrl
      video
      headerNews
      date
      title
      source
      assetsTags {
        title
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHouse = /* GraphQL */ `
  mutation CreateHouse(
    $input: CreateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    createHouse(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      photo
      photoS3 {
        bucket
        region
        key
      }
      fileS3 {
        bucket
        region
        key
      }
      Low1Y
      State
      city
      recom
      title
      place
      symbolID
      description
      ShareAvailable
      newBuilding
      Address
      price
      priceLive
      Net
      longitude
      PrimaryOfferID
      SecondaryOfferID
      IPOENDDATE
      NextTrading
      cityAddress
      High1Y
      commodity
      vol
      growth
      trend
      onboardFA
      onboardRE
      onboardMA
      onboardENG
      LastPriceUpdate
      HouseStatus
      RentDiv
      NextDiv
      numberOfInvstors
      mercuryAccountID
      TradingWindow
      ulLink
      latitude
      earlyAccess
      haltTrading
      CAP
      fullprice
      News {
        id
        url
        imageUrl
        date
        title
        source
      }
      Neighborhood {
        PricePerSqft
        AverageSale
        Name
        CommuteRate
        SchoolRate
        JobRate
        WalkRate
        WeatherRate
        Description
        MonthlyRent
        NGRate
        jobGrowth
        Unemployment
        RentGrowth
        DaysInMarket
        PopulationGrowth
      }
      HouseChart {
        y
        x
      }
      ShareChartTicks {
        y
        x
      }
      OpenChartTicks {
        y
        x
      }
      Documents {
        Doc1
        Doc2
        Doc3
        Doc4
        Doc5
      }
      Details {
        Type
        Lot
        Heating
        YearB
        Bathroom
        Parking
        Cooling
        YearRenov
        Bedroom
        House
      }
      ShareChart {
        GainLable
        GainTrend
        Gain
        Rent
        RentP
        GainP
      }
      nextRentPerShare
      nextRentDate
      photos
      photosS3 {
        bucket
        region
        key
      }
      Highlights {
        text
        title
        id
      }
      Financials {
        ProfitAnnual
        PricePsqft
        ExpencesAnnual
        LoanValue
        Bonus
        closing
        Renovation
        Brokerage
        RentAnnual
        NumShares
        DebtsAnnual
        LoanTovalue
        LoanInterstRate
        CashReserve
      }
    }
  }
`;
export const updateHouse = /* GraphQL */ `
  mutation UpdateHouse(
    $input: UpdateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    updateHouse(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      photo
      photoS3 {
        bucket
        region
        key
      }
      fileS3 {
        bucket
        region
        key
      }
      Low1Y
      State
      city
      recom
      title
      place
      symbolID
      description
      ShareAvailable
      newBuilding
      Address
      price
      priceLive
      Net
      longitude
      PrimaryOfferID
      SecondaryOfferID
      IPOENDDATE
      NextTrading
      cityAddress
      High1Y
      commodity
      vol
      growth
      trend
      onboardFA
      onboardRE
      onboardMA
      onboardENG
      LastPriceUpdate
      HouseStatus
      RentDiv
      NextDiv
      numberOfInvstors
      mercuryAccountID
      TradingWindow
      ulLink
      latitude
      earlyAccess
      haltTrading
      CAP
      fullprice
      News {
        id
        url
        imageUrl
        date
        title
        source
      }
      Neighborhood {
        PricePerSqft
        AverageSale
        Name
        CommuteRate
        SchoolRate
        JobRate
        WalkRate
        WeatherRate
        Description
        MonthlyRent
        NGRate
        jobGrowth
        Unemployment
        RentGrowth
        DaysInMarket
        PopulationGrowth
      }
      HouseChart {
        y
        x
      }
      ShareChartTicks {
        y
        x
      }
      OpenChartTicks {
        y
        x
      }
      Documents {
        Doc1
        Doc2
        Doc3
        Doc4
        Doc5
      }
      Details {
        Type
        Lot
        Heating
        YearB
        Bathroom
        Parking
        Cooling
        YearRenov
        Bedroom
        House
      }
      ShareChart {
        GainLable
        GainTrend
        Gain
        Rent
        RentP
        GainP
      }
      nextRentPerShare
      nextRentDate
      photos
      photosS3 {
        bucket
        region
        key
      }
      Highlights {
        text
        title
        id
      }
      Financials {
        ProfitAnnual
        PricePsqft
        ExpencesAnnual
        LoanValue
        Bonus
        closing
        Renovation
        Brokerage
        RentAnnual
        NumShares
        DebtsAnnual
        LoanTovalue
        LoanInterstRate
        CashReserve
      }
    }
  }
`;
export const deleteHouse = /* GraphQL */ `
  mutation DeleteHouse(
    $input: DeleteHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    deleteHouse(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      photo
      photoS3 {
        bucket
        region
        key
      }
      fileS3 {
        bucket
        region
        key
      }
      Low1Y
      State
      city
      recom
      title
      place
      symbolID
      description
      ShareAvailable
      newBuilding
      Address
      price
      priceLive
      Net
      longitude
      PrimaryOfferID
      SecondaryOfferID
      IPOENDDATE
      NextTrading
      cityAddress
      High1Y
      commodity
      vol
      growth
      trend
      onboardFA
      onboardRE
      onboardMA
      onboardENG
      LastPriceUpdate
      HouseStatus
      RentDiv
      NextDiv
      numberOfInvstors
      mercuryAccountID
      TradingWindow
      ulLink
      latitude
      earlyAccess
      haltTrading
      CAP
      fullprice
      News {
        id
        url
        imageUrl
        date
        title
        source
      }
      Neighborhood {
        PricePerSqft
        AverageSale
        Name
        CommuteRate
        SchoolRate
        JobRate
        WalkRate
        WeatherRate
        Description
        MonthlyRent
        NGRate
        jobGrowth
        Unemployment
        RentGrowth
        DaysInMarket
        PopulationGrowth
      }
      HouseChart {
        y
        x
      }
      ShareChartTicks {
        y
        x
      }
      OpenChartTicks {
        y
        x
      }
      Documents {
        Doc1
        Doc2
        Doc3
        Doc4
        Doc5
      }
      Details {
        Type
        Lot
        Heating
        YearB
        Bathroom
        Parking
        Cooling
        YearRenov
        Bedroom
        House
      }
      ShareChart {
        GainLable
        GainTrend
        Gain
        Rent
        RentP
        GainP
      }
      nextRentPerShare
      nextRentDate
      photos
      photosS3 {
        bucket
        region
        key
      }
      Highlights {
        text
        title
        id
      }
      Financials {
        ProfitAnnual
        PricePsqft
        ExpencesAnnual
        LoanValue
        Bonus
        closing
        Renovation
        Brokerage
        RentAnnual
        NumShares
        DebtsAnnual
        LoanTovalue
        LoanInterstRate
        CashReserve
      }
    }
  }
`;
export const createSubsList = /* GraphQL */ `
  mutation CreateSubsList(
    $input: CreateSubsListInput!
    $condition: ModelSubsListConditionInput
  ) {
    createSubsList(input: $input, condition: $condition) {
      id
      listID
      userID
      subscription
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const updateSubsList = /* GraphQL */ `
  mutation UpdateSubsList(
    $input: UpdateSubsListInput!
    $condition: ModelSubsListConditionInput
  ) {
    updateSubsList(input: $input, condition: $condition) {
      id
      listID
      userID
      subscription
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const deleteSubsList = /* GraphQL */ `
  mutation DeleteSubsList(
    $input: DeleteSubsListInput!
    $condition: ModelSubsListConditionInput
  ) {
    deleteSubsList(input: $input, condition: $condition) {
      id
      listID
      userID
      subscription
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
