// in src/users.js
import * as React from "react";
import {
  List,
  Create,
  RadioButtonGroupInput,
  Datagrid,
  AutocompleteInput,
  required,
  TextField,
  ArrayField,
  DateField,
  BooleanField,
  EditButton,
  Edit,
  SimpleForm,
  DateTimeInput,
  TextInput,
  NumberInput,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import { useListContext, NumberField, ChipField } from "react-admin";
import { Button, Toolbar, Box, Grid } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AccordionForm from "./accordion-form/AccordionForm.js";
import AccordionFormPanel from "./accordion-form/AccordionFormPanel.js";
import {
  AmplifyFileInput,
  AmplifyFilter,
  AmplifyImageInput,
} from "react-admin-amplify";
import AutoCompleteGoogle from "./autoCompleteGoogle";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider"
import classnames from "classnames";
import { Show, SimpleShowLayout } from "react-admin";

const defaultQuery = "listHouses";

const useStyles = makeStyles({
  done: { backgroundColor: "#74fd74" },
  progress: { backgroundColor: "yellow" },
});

const ColoredChipField = (props) => {
  const classes = useStyles();

  const isDone = (v) => v === "Done";
  const isPROGRESS = (v) => v === "Incomplete";

  return (
    <ChipField
      className={classnames(
        {
          [classes.done]: isDone(props.record[props.source]),
        },
        {
          [classes.progress]: isPROGRESS(props.record[props.source]),
        }
      )}
      {...props}
    />
  );
};

export const HouseList = (props) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <List {...props} filters={<HouseFilter setQuery={setQuery} />} perPage={25}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="Address" />
        <ChipField source="HouseStatus" />
        <TextField label="Next Dividends" source="NextDiv" />


        {/* <NumberField label="PPS" source="price" options={{ style: 'currency', currency: 'USD' }}/> */}

        <EditButton />
      </Datagrid>
    </List>
  );
};

const PostTitle = ({ record }) => {
  return <span>House {record ? `"${record.title}"` : ""}</span>;
};

const PostTitle2 = ({ record }) => {
  return <span> </span>;
};

export const HouseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
  <TextField
    helperText="H-TICKER-STATE: H-ALPHA-TX"
    source="id"
    
  />
<TextField helperText="TIKER : #ALPHA" source="title"    />
  <TextField
    label="Metro"
    helperText="City, State: Houston, TX"
    source="place"
    
  />


  <TextField helperText="811 Spinnaker Ct" source="Address" />

<TextField  helperText="Listing Status: IPO" source="HouseStatus" />


  <TextField  source="State" />

 
  <NumberField helperText="price: 5.00" label="Price Per Share" source="price" />

  <NumberField
          helperText="Dividend per share for next month"
          label="Next Dividends"
          source="NextDiv"
        />

  <TextField
    label="Market Cap"
    helperText="IPO Market Cap"
    source="CAP"
  />
  <NumberField
    helperText="Number of Available Shares"
    source="ShareAvailable"
  />

<TextField
          helperText="Total Number of Shares"
          label="Total Shares"
          source="Financials.NumShares"
        />

<TextField
          helperText="Closing and Offering Cost"
          label="Closing Cost"
          source="Financials.closing"
        />

<TextField
          helperText="Operating and Cash Reserve"
          label="Cash Reserve"
          source="Financials.CashReserve"
        />

<TextField
          helperText="Loan Value in USD"
          label="Loan Amount"
          source="Financials.LoanValue"
        />

<TextField
          helperText="Annual Rent income in USD"
          label="Annual Rent"
          source="Financials.RentAnnual"
        />

<TextField
          helperText="Annual Expences in USD"
          label="Annual Expences"
          source="Financials.ExpencesAnnual"
        />

<TextField
          helperText="Annual Debts in USD"
          label="Annual Debts"
          source="Financials.DebtsAnnual"
        />

<TextField
          helperText="Annual Profit in USD"
          label="Annual Profit"
          source="Financials.ProfitAnnual"
        />

  
  
<NumberField
    lable="Dividend per share"
    source="nextRentPerShare"
  />





  


    </SimpleShowLayout>
  </Show>
);

const HouseFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <TextInput
      source="findTitle.title"
      label="Search Title"
      alwaysOn
      resettable
    />
        
   
    <TextInput
      source="findState.State"
      label="State Abbr."
      resettable
    />
  </AmplifyFilter>
);


export const HouseEdit = (props) => {
  return (
    <Edit {...props}>
              <SimpleForm>

        <TextInput
        disabled
          helperText="H-TICKER-STATE: H-ALPHA-TX"
          source="id"
          validate={[required()]}
        />
      <TextInput disabled helperText="TIKER : #ALPHA" source="title"    validate={[required()]}/>
        <TextInput
        disabled
          label="Metro"
          helperText="City, State: Houston, TX"
          source="place"
          validate={[required()]}
        />
       
   

        <TextInput disabled helperText="811 Spinnaker Ct" source="Address" validate={[required()]}/>
 {/* <AutoCompleteGoogle 
                        source="Address"
                        variant="outlined"
                      />  */}

 

        <AutocompleteInput  disabled source="State" choices={[
    { name: 'Arizona', id: 'AZ' },
    { name: 'Alabama', id: 'AL' },
    { name: 'Alaska', id: 'AK' },
    { name: 'Arkansas', id: 'AR' },
    { name: 'California', id: 'CA' },
    { name: 'Colorado', id: 'CO' },
    { name: 'Connecticut', id: 'CT' },
    { name: 'Delaware', id: 'DE' },
    { name: 'Florida', id: 'FL' },
    { name: 'Georgia', id: 'GA' },
    { name: 'Hawaii', id: 'HI' },
    { name: 'Idaho', id: 'ID' },
    { name: 'Illinois', id: 'IL' },
    { name: 'Indiana', id: 'IN' },
    { name: 'Iowa', id: 'IA' },
    { name: 'Kansas', id: 'KS' },
    { name: 'Kentucky', id: 'KY' },
    { name: 'Louisiana', id: 'LA' },
    { name: 'Maine', id: 'ME' },
    { name: 'Maryland', id: 'MD' },
    { name: 'Massachusetts', id: 'MA' },
    { name: 'Michigan', id: 'MI' },
    { name: 'Minnesota', id: 'MN' },
    { name: 'Mississippi', id: 'MS' },
    { name: 'Missouri', id: 'MO' },
    { name: 'Montana', id: 'MT' },
    { name: 'Nebraska', id: 'NE' },
    { name: 'Nevada', id: 'NV' },
    { name: 'New Hampshire', id: 'NH' },
    { name: 'New Jersey', id: 'NJ' },
    { name: 'New Mexico', id: 'NM' },
    { name: 'New York', id: 'NY' },
    { name: 'North Carolina', id: 'NC' },
    { name: 'North Dakota', id: 'ND' },
    { name: 'Ohio', id: 'OH' },
    { name: 'Oklahoma', id: 'OK' },
    { name: 'Oregon', id: 'OR' },
    { name: 'Pennsylvania', id: 'PA' },
    { name: 'Rhode Island', id: 'RI' },
    { name: 'South Carolina', id: 'SC' },
    { name: 'South Dakota', id: 'SD' },
    { name: 'Tennessee', id: 'TN' },
    { name: 'Texas', id: 'TX' },
    { name: 'Utah', id: 'UT' },
    { name: 'Vermont', id: 'VT' },
    { name: 'Virginia', id: 'VA' },
    { name: 'Washington', id: 'WA' },
    { name: 'West Virginia', id: 'WV' },
    { name: 'Wisconsin', id: 'WI' },
    { name: 'Wyoming', id: 'WY' },
]}/>
 
       

      

 <AutocompleteInput  disabled helperText="Listing Status: IPO" source="HouseStatus" 
disabled choices={[
    { id: 'IPO', name: 'IPO' },
    { id: 'NONE', name: 'Not Listed' },
    { id: 'BLUR', name: 'Blurred' },
    { id: 'SOLD', name: 'Sold Out' },
    { id: 'Trading', name: 'Trading' },
    { id: 'COMING', name: 'Testing the water' },

    
]}/>

<NumberInput disabled helperText="price: 5.00" label="Price Per Share" source="price" />


<NumberInput
          helperText="Dividend per share for next month"
          label="Next Dividends"
          source="NextDiv"
        />


        <TextInput
          label="Market Cap"
          helperText="IPO Market Cap"
          source="CAP"
        />



<NumberInput
          helperText="Operating and Cash Reserve"
          label="Cash Reserve"
          source="Financials.CashReserve"
        />

<NumberInput
          helperText="Loan Value in USD"
          label="Loan Amount"
          source="Financials.LoanValue"
        />

<NumberInput
          helperText="Annual Rent income in USD"
          label="Annual Rent"
          source="Financials.RentAnnual"
        />

<NumberInput
          helperText="Annual Expences in USD"
          label="Annual Expences"
          source="Financials.ExpencesAnnual"
        />

<NumberInput
          helperText="Annual Debts in USD"
          label="Annual Debts"
          source="Financials.DebtsAnnual"
        />

<NumberInput
          helperText="Annual Profit in USD"
          label="Annual Profit"
          source="Financials.ProfitAnnual"
        />



<NumberInput
          helperText="For the next distribution"
          lable="Dividend per share"
          source="nextRentPerShare"
        />


        
</SimpleForm>



    </Edit>
  );
};



const PostPagination = () => {
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  return (
    nbPages > 1 && (
      <Toolbar>
        {page > 1 && (
          <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
            <ChevronLeft />
            Prev
          </Button>
        )}
        {page !== nbPages && (
          <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
            Next
            <ChevronRight />
          </Button>
        )}
      </Toolbar>
    )
  );
};


 //   Low1Y: Secondary
//   High1Y: Float
//   ShareChartTicks: [Ticks2]
//   OpenChartTicks: [Ticks2]
//   photo: String
//   photos: [String]



//   photoS3: S3Object
//   photosS3: [S3Object]
//   fileS3: S3Object
//   onboardMA: String
//   onboardENG: String
//   News: [News]
//   HouseChart: [Ticks]



//   Documents: Documents
//   ShareChart: ShareChart


  ///  **********



// id: ID!
//   createdAt: AWSDateTime
//   updatedAt: AWSDateTime
//   photo: String
//   photoS3: S3Object
//   fileS3: S3Object
//   Low1Y: Float
//   State: String
//   recom: Int
//   title: String
//   place: String
//   symbolID: String
//   description: String
//   ShareAvailable: Int
//   newBuilding: Boolean
//   Address: String
//   price: Float
//   priceLive: Float
//   Net: Float
//   longitude: String
//   PrimaryOfferID: String
//   SecondaryOfferID: String
//   IPOENDDATE: AWSDateTime
//   NextTrading: AWSDateTime
//   cityAddress: String
//   High1Y: Float
//   commodity: String
//   type: String
//   vol: Int
//   growth: Float
//   trend: String
//   onboardFA: String
//   onboardRE: String
//   onboardMA: String
//   onboardENG: String
//   LastPriceUpdate: AWSDateTime
//   HouseStatus: String
//   RentDiv: Float
//   numberOfInvstors: Int
//   mercuryAccountID: String
//   TradingWindow: Boolean
//   latitude: String
//   CAP: String
//   fullprice: Int
//   News: [News]
//   Neighborhood: Neighborhood
//   HouseChart: [Ticks]
//   ShareChartTicks: [Ticks2]
//   OpenChartTicks: [Ticks2]
//   Documents: Documents
//   Details: Details
//   ShareChart: ShareChart
//   nextRentPerShare: Float
//   nextRentDate: AWSDateTime
//   photos: [String]
//   photosS3: [S3Object]
