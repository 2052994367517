/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchGet = /* GraphQL */ `
  query BatchGet($ids: [ID]) {
    batchGet(ids: $ids) {
      id
      digits
      email
      invitationStatus
      acceptedInvitation
      StatusHistory {
        status
        time
      }
      firstApply
      lastInviter
      allInviters {
        inviter
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const getExpoToken = /* GraphQL */ `
  query GetExpoToken($token: String!) {
    getExpoToken(token: $token) {
      id
      token
      playerUsername
      createdAt
      updatedAt
    }
  }
`;
export const listExpoTokens = /* GraphQL */ `
  query ListExpoTokens(
    $token: String
    $filter: ModelExpoTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExpoTokens(
      token: $token
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        token
        playerUsername
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExpoTicketsObject = /* GraphQL */ `
  query GetExpoTicketsObject($id: ID!) {
    getExpoTicketsObject(id: $id) {
      id
      tickets
      createdAt
      updatedAt
    }
  }
`;
export const listExpoTicketsObjects = /* GraphQL */ `
  query ListExpoTicketsObjects(
    $filter: ModelExpoTicketsObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpoTicketsObjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tickets
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwnership = /* GraphQL */ `
  query GetOwnership($userID: String!, $assetID: String!) {
    getOwnership(userID: $userID, assetID: $assetID) {
      userID
      assetID
      totalShares
      city
      title
      ownershipStatus
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const listOwnerships = /* GraphQL */ `
  query ListOwnerships(
    $userID: String
    $assetID: ModelStringKeyConditionInput
    $filter: ModelownershipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOwnerships(
      userID: $userID
      assetID: $assetID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        assetID
        totalShares
        city
        title
        ownershipStatus
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const findByAsset = /* GraphQL */ `
  query FindByAsset(
    $assetID: String
    $sortDirection: ModelSortDirection
    $filter: ModelownershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByAsset(
      assetID: $assetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        assetID
        totalShares
        city
        title
        ownershipStatus
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const getGift = /* GraphQL */ `
  query GetGift($id: ID!) {
    getGift(id: $id) {
      id
      sub
      GiftReason
      GiftType
      GiftStatus
      NumberOfShares
      AssetTicket
      TriggerDate
      createdAt
      updatedAt
    }
  }
`;
export const listGifts = /* GraphQL */ `
  query ListGifts(
    $filter: ModelGiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        GiftReason
        GiftType
        GiftStatus
        NumberOfShares
        AssetTicket
        TriggerDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($sub: String!) {
    getUser(sub: $sub) {
      email
      sub
      username
      firstName
      notification
      enotification
      theme
      addressCity
      faceID
      addressStreet
      streetAddress
      unitAddress
      createdAt
      updatedAt
      city
      state
      zipcode
      country
      phoneNumber
      displayName
      AvatarKey
      AvatarURL
      ChatToken
      lastName
      appTheme
      badgeCount
      updateType
      AtsUserId
      ChatGuide
      AtsApplicationId
      AtsAccountId
      AtsBankAccountUID
      LegalFirstName
      LegalLastName
      GiftStatus
      InvestmentLimit
      cash {
        amount
        power
        withdraw
        reserved
        transit
        primary
      }
      tutorial1
      tutorial2
      tutorial12
      verification
      verificationProgress
      usertype
      type
      notificationBadge
      notify
      availableInvitations
      acceptedInvites
      pendingInvites
      expiredInvites
      watchList
      mercuryID
      invitedby
      bankConnected
      tokens {
        items {
          id
          token
          playerUsername
          createdAt
          updatedAt
        }
        nextToken
      }
      ownerships {
        items {
          userID
          assetID
          totalShares
          city
          title
          ownershipStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      PlaidToken {
        items {
          id
          BankAccountId
          ITEM_ID
          ACCESS_TOKEN
          processorToken
          createdAt
          updatedAt
        }
        nextToken
      }
      contactlist {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
      InitOrders {
        items {
          id
          commodity
          totalfee
          totalAmount
          walletAmount
          shares
          sharePrice
          HouseId
          UserID
          AtsOrderID
          type
          orderStatus
          date
          createdAt
          updatedAt
          escrowPaymentID
          dwTransferID
        }
        nextToken
      }
      DividendPayments {
        items {
          id
          userId
          assetId
          totalAmount
          paytype
          shares
          payPeriod
          payStatus
          transactionMercuryID
          payDate
          createdAt
          updatedAt
        }
        nextToken
      }
      activities {
        items {
          id
          type
          title
          description
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      TradingOrders {
        items {
          id
          AtsOrderID
          AtsExecutionId
          AtsParentOrderId
          AtsTransactionId
          commodity
          totalfee
          totalAmount
          shares
          sharePrice
          type
          orderStatus
          symbolId
          symbol
          sideId
          AtsAccountId
          AtsUserId
          AtsTradeId
          AtsTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      Transactions {
        items {
          amount
          AtsOrderID
          bankTransactionUID
          createdAt
          updatedAt
          paymentStatus
          type
          primaryOrder
        }
        nextToken
      }
      SubsLists {
        items {
          id
          listID
          userID
          subscription
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $sub: String
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      sub: $sub
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findByUsername = /* GraphQL */ `
  query FindByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const itemsByPhone = /* GraphQL */ `
  query ItemsByPhone(
    $phoneNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByPhone(
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findByAtsUserId = /* GraphQL */ `
  query FindByAtsUserId(
    $AtsUserId: Int
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByAtsUserId(
      AtsUserId: $AtsUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findByAtsAccountId = /* GraphQL */ `
  query FindByAtsAccountId(
    $AtsAccountId: Int
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByAtsAccountId(
      AtsAccountId: $AtsAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findAtsApplicationId = /* GraphQL */ `
  query FindAtsApplicationId(
    $AtsApplicationId: Int
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAtsApplicationId(
      AtsApplicationId: $AtsApplicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findVerifyATS = /* GraphQL */ `
  query FindVerifyATS(
    $verification: verificationType
    $AtsApplicationId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findVerifyATS(
      verification: $verification
      AtsApplicationId: $AtsApplicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findByGiftStatus = /* GraphQL */ `
  query FindByGiftStatus(
    $GiftStatus: String
    $sortDirection: ModelSortDirection
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByGiftStatus(
      GiftStatus: $GiftStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContactUS = /* GraphQL */ `
  query GetContactUS($id: ID!) {
    getContactUS(id: $id) {
      id
      email
      message
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const listContactUSs = /* GraphQL */ `
  query ListContactUSs(
    $filter: ModelContactUSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUSs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        message
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        sub
      }
      nextToken
    }
  }
`;
export const getSellYourHouse = /* GraphQL */ `
  query GetSellYourHouse($id: ID!) {
    getSellYourHouse(id: $id) {
      id
      streetAddress
      unitAddress
      city
      state
      zipcode
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      sub
    }
  }
`;
export const listSellYourHouses = /* GraphQL */ `
  query ListSellYourHouses(
    $filter: ModelSellYourHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellYourHouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        streetAddress
        unitAddress
        city
        state
        zipcode
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        sub
      }
      nextToken
    }
  }
`;
export const getPlaidToken = /* GraphQL */ `
  query GetPlaidToken($id: ID!) {
    getPlaidToken(id: $id) {
      id
      BankAccountId
      ITEM_ID
      ACCESS_TOKEN
      processorToken
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listPlaidTokens = /* GraphQL */ `
  query ListPlaidTokens(
    $filter: ModelPlaidTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaidTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BankAccountId
        ITEM_ID
        ACCESS_TOKEN
        processorToken
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const getListToAll = /* GraphQL */ `
  query GetListToAll($id: ID!) {
    getListToAll(id: $id) {
      id
      AllContactID
      UserID
      createdAt
      updatedAt
      User {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      AllContact {
        id
        firstName
        jobTitle
        company
        lastName
        middleName
        name
        status
        countryCode
        digits
        number
        city
        country
        isoCountryCode
        label
        postalCode
        region
        street
        createdAt
        updatedAt
        connections {
          nextToken
        }
      }
    }
  }
`;
export const listListToAlls = /* GraphQL */ `
  query ListListToAlls(
    $filter: ModelListToAllFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListToAlls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AllContactID
        UserID
        createdAt
        updatedAt
        User {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        AllContact {
          id
          firstName
          jobTitle
          company
          lastName
          middleName
          name
          status
          countryCode
          digits
          number
          city
          country
          isoCountryCode
          label
          postalCode
          region
          street
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAllContact = /* GraphQL */ `
  query GetAllContact($id: ID!) {
    getAllContact(id: $id) {
      id
      firstName
      jobTitle
      company
      lastName
      middleName
      name
      status
      countryCode
      digits
      number
      city
      country
      isoCountryCode
      label
      postalCode
      region
      street
      createdAt
      updatedAt
      connections {
        items {
          id
          AllContactID
          UserID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAllContacts = /* GraphQL */ `
  query ListAllContacts(
    $filter: ModelAllContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        jobTitle
        company
        lastName
        middleName
        name
        status
        countryCode
        digits
        number
        city
        country
        isoCountryCode
        label
        postalCode
        region
        street
        createdAt
        updatedAt
        connections {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getInitOrder = /* GraphQL */ `
  query GetInitOrder($id: ID!) {
    getInitOrder(id: $id) {
      id
      commodity
      totalfee
      totalAmount
      walletAmount
      shares
      sharePrice
      HouseId
      UserID
      AtsOrderID
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      escrowPaymentID
      dwTransferID
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const listInitOrders = /* GraphQL */ `
  query ListInitOrders(
    $filter: ModelInitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commodity
        totalfee
        totalAmount
        walletAmount
        shares
        sharePrice
        HouseId
        UserID
        AtsOrderID
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        escrowPaymentID
        dwTransferID
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const listbyStatus = /* GraphQL */ `
  query ListbyStatus(
    $orderStatus: OrderStatus
    $HouseId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyStatus(
      orderStatus: $orderStatus
      HouseId: $HouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commodity
        totalfee
        totalAmount
        walletAmount
        shares
        sharePrice
        HouseId
        UserID
        AtsOrderID
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        escrowPaymentID
        dwTransferID
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const findbyAtsOrderID = /* GraphQL */ `
  query FindbyAtsOrderID(
    $AtsOrderID: String
    $sortDirection: ModelSortDirection
    $filter: ModelInitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findbyAtsOrderID(
      AtsOrderID: $AtsOrderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commodity
        totalfee
        totalAmount
        walletAmount
        shares
        sharePrice
        HouseId
        UserID
        AtsOrderID
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        escrowPaymentID
        dwTransferID
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const listbyOrderStatus = /* GraphQL */ `
  query ListbyOrderStatus(
    $orderStatus: OrderStatus
    $sortDirection: ModelSortDirection
    $filter: ModelInitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbyOrderStatus(
      orderStatus: $orderStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commodity
        totalfee
        totalAmount
        walletAmount
        shares
        sharePrice
        HouseId
        UserID
        AtsOrderID
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        escrowPaymentID
        dwTransferID
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const listbydwTransferID = /* GraphQL */ `
  query ListbydwTransferID(
    $dwTransferID: String
    $sortDirection: ModelSortDirection
    $filter: ModelInitOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listbydwTransferID(
      dwTransferID: $dwTransferID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commodity
        totalfee
        totalAmount
        walletAmount
        shares
        sharePrice
        HouseId
        UserID
        AtsOrderID
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        escrowPaymentID
        dwTransferID
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const getDividendPayment = /* GraphQL */ `
  query GetDividendPayment($id: ID!) {
    getDividendPayment(id: $id) {
      id
      userId
      assetId
      totalAmount
      paytype
      shares
      payPeriod
      payStatus
      transactionMercuryID
      payDate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      house {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const listDividendPayments = /* GraphQL */ `
  query ListDividendPayments(
    $filter: ModelDividendPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDividendPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assetId
        totalAmount
        paytype
        shares
        payPeriod
        payStatus
        transactionMercuryID
        payDate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const allUserDividend = /* GraphQL */ `
  query AllUserDividend(
    $userId: String
    $payStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDividendPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allUserDividend(
      userId: $userId
      payStatus: $payStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assetId
        totalAmount
        paytype
        shares
        payPeriod
        payStatus
        transactionMercuryID
        payDate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const divUserPeriod = /* GraphQL */ `
  query DivUserPeriod(
    $userId: String
    $payPeriod: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDividendPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    divUserPeriod(
      userId: $userId
      payPeriod: $payPeriod
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assetId
        totalAmount
        paytype
        shares
        payPeriod
        payStatus
        transactionMercuryID
        payDate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const divUserPeriodAsset = /* GraphQL */ `
  query DivUserPeriodAsset(
    $userId: String
    $payPeriodAssetId: ModelDividendPaymentDivByUserPeriodAssetCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDividendPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    divUserPeriodAsset(
      userId: $userId
      payPeriodAssetId: $payPeriodAssetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assetId
        totalAmount
        paytype
        shares
        payPeriod
        payStatus
        transactionMercuryID
        payDate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        house {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      type
      title
      description
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelactivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        date
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const getOpenTradingOrder = /* GraphQL */ `
  query GetOpenTradingOrder($id: ID!) {
    getOpenTradingOrder(id: $id) {
      id
      commodity
      totalfee
      HouseId
      totalAmount
      shares
      AtsOrderId
      filledQuantity
      containsPartialFills
      filledDate
      sharePrice
      type
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      orderStatus
      date
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const listOpenTradingOrders = /* GraphQL */ `
  query ListOpenTradingOrders(
    $filter: ModelOpenTradingOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenTradingOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commodity
        totalfee
        HouseId
        totalAmount
        shares
        AtsOrderId
        filledQuantity
        containsPartialFills
        filledDate
        sharePrice
        type
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        orderStatus
        date
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const getTradingOrder = /* GraphQL */ `
  query GetTradingOrder($id: ID!) {
    getTradingOrder(id: $id) {
      id
      AtsOrderID
      AtsExecutionId
      AtsParentOrderId
      AtsTransactionId
      commodity
      totalfee
      totalAmount
      shares
      sharePrice
      type
      orderStatus
      symbolId
      symbol
      sideId
      AtsAccountId
      AtsUserId
      AtsTradeId
      AtsTimestamp
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
      House {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
    }
  }
`;
export const listTradingOrders = /* GraphQL */ `
  query ListTradingOrders(
    $filter: ModelTradingOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTradingOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AtsOrderID
        AtsExecutionId
        AtsParentOrderId
        AtsTransactionId
        commodity
        totalfee
        totalAmount
        shares
        sharePrice
        type
        orderStatus
        symbolId
        symbol
        sideId
        AtsAccountId
        AtsUserId
        AtsTradeId
        AtsTimestamp
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
        House {
          id
          createdAt
          updatedAt
          photo
          Low1Y
          State
          city
          recom
          title
          place
          symbolID
          description
          ShareAvailable
          newBuilding
          Address
          price
          priceLive
          Net
          longitude
          PrimaryOfferID
          SecondaryOfferID
          IPOENDDATE
          NextTrading
          cityAddress
          High1Y
          commodity
          vol
          growth
          trend
          onboardFA
          onboardRE
          onboardMA
          onboardENG
          LastPriceUpdate
          HouseStatus
          RentDiv
          NextDiv
          numberOfInvstors
          mercuryAccountID
          TradingWindow
          ulLink
          latitude
          earlyAccess
          haltTrading
          CAP
          fullprice
          nextRentPerShare
          nextRentDate
          photos
        }
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($bankTransactionUID: String!) {
    getTransaction(bankTransactionUID: $bankTransactionUID) {
      amount
      AtsOrderID
      bankTransactionUID
      createdAt
      updatedAt
      paymentStatus
      Identity {
        defaultAuthStrategy
        groups
        issuer
        sub
        username
        sourceIp
      }
      claims {
        sub
        event_id
        token_use
        scope
        auth_time
        iss
        exp
        iat
        jti
        client_id
        username
      }
      type
      primaryOrder
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $bankTransactionUID: String
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransactions(
      bankTransactionUID: $bankTransactionUID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amount
        AtsOrderID
        bankTransactionUID
        createdAt
        updatedAt
        paymentStatus
        Identity {
          defaultAuthStrategy
          groups
          issuer
          sub
          username
          sourceIp
        }
        claims {
          sub
          event_id
          token_use
          scope
          auth_time
          iss
          exp
          iat
          jti
          client_id
          username
        }
        type
        primaryOrder
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      digits
      email
      invitationStatus
      acceptedInvitation
      StatusHistory {
        status
        time
      }
      firstApply
      lastInviter
      allInviters {
        inviter
        time
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        digits
        email
        invitationStatus
        acceptedInvitation
        StatusHistory {
          status
          time
        }
        firstApply
        lastInviter
        allInviters {
          inviter
          time
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitedEmails = /* GraphQL */ `
  query GetInvitedEmails($id: ID!) {
    getInvitedEmails(id: $id) {
      id
      referals
      applieTime
      createdAt
      updatedAt
    }
  }
`;
export const listInvitedEmailss = /* GraphQL */ `
  query ListInvitedEmailss(
    $filter: ModelInvitedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedEmailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        referals
        applieTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaitingList = /* GraphQL */ `
  query GetWaitingList($digits: String!) {
    getWaitingList(digits: $digits) {
      id
      digits
      email
      waitStatus
      allInviters
      sub
      createdAt
      updatedAt
    }
  }
`;
export const listWaitingLists = /* GraphQL */ `
  query ListWaitingLists(
    $digits: String
    $filter: ModelWaitingListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWaitingLists(
      digits: $digits
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        digits
        email
        waitStatus
        allInviters
        sub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const waitlistByEmail = /* GraphQL */ `
  query WaitlistByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelWaitingListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    waitlistByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        digits
        email
        waitStatus
        allInviters
        sub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserVerification = /* GraphQL */ `
  query GetUserVerification($id: ID!) {
    getUserVerification(id: $id) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listUserVerifications = /* GraphQL */ `
  query ListUserVerifications(
    $filter: ModelUserVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        phoneNumber
        countryCode
        dateOfBirth
        streetAddress
        unitAddress
        city
        stateAddress
        zipcode
        country
        streetAddressJ
        unitAddressJ
        cityJ
        stateJ
        zipcodeJ
        countryJ
        decision
        riskProfile
        investmentObject
        accredited
        qualified
        finraName
        risk
        objective
        fundSource
        publicCName
        governEmployee
        governTitle
        governBranch
        governWealthS
        governpepName
        governpepRelation
        ssn
        finra
        publicC
        govern
        finraExtra
        termsAgreed
        maritial
        income
        net
        liqudity
        jobStatus
        jobTitle
        jobOcc
        employer
        TrustedfirstName
        TrustedlastName
        Trustedemail
        Trustedphone
        Trustedalternate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const getUserVerificationRaw = /* GraphQL */ `
  query GetUserVerificationRaw($id: ID!) {
    getUserVerificationRaw(id: $id) {
      id
      firstName
      middleName
      lastName
      suffix
      phoneNumber
      countryCode
      dateOfBirth
      streetAddress
      unitAddress
      city
      stateAddress
      zipcode
      country
      place
      placeJ
      lat
      long
      streetAddressJ
      unitAddressJ
      cityJ
      stateJ
      zipcodeJ
      countryJ
      decision
      riskProfile
      investmentObject
      accredited
      qualified
      finraName
      risk
      objective
      fundSource
      publicCName
      governEmployee
      governTitle
      governBranch
      governWealthS
      governpepName
      governpepRelation
      ssn
      finra
      noFinra
      publicC
      govern
      finraExtra
      termsAgreed
      maritial
      income
      net
      liqudity
      jobStatus
      jobTitle
      jobOcc
      employer
      TrustedfirstName
      TrustedlastName
      Trustedemail
      Trustedphone
      Trustedalternate
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listUserVerificationRaws = /* GraphQL */ `
  query ListUserVerificationRaws(
    $filter: ModelUserVerificationRawFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserVerificationRaws(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        suffix
        phoneNumber
        countryCode
        dateOfBirth
        streetAddress
        unitAddress
        city
        stateAddress
        zipcode
        country
        place
        placeJ
        lat
        long
        streetAddressJ
        unitAddressJ
        cityJ
        stateJ
        zipcodeJ
        countryJ
        decision
        riskProfile
        investmentObject
        accredited
        qualified
        finraName
        risk
        objective
        fundSource
        publicCName
        governEmployee
        governTitle
        governBranch
        governWealthS
        governpepName
        governpepRelation
        ssn
        finra
        noFinra
        publicC
        govern
        finraExtra
        termsAgreed
        maritial
        income
        net
        liqudity
        jobStatus
        jobTitle
        jobOcc
        employer
        TrustedfirstName
        TrustedlastName
        Trustedemail
        Trustedphone
        Trustedalternate
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const getAppConfig = /* GraphQL */ `
  query GetAppConfig($id: ID!) {
    getAppConfig(id: $id) {
      id
      secondaryOpen
      forceUpdate
      dynamicLinkURL
      createdAt
      updatedAt
    }
  }
`;
export const listAppConfigs = /* GraphQL */ `
  query ListAppConfigs(
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        secondaryOpen
        forceUpdate
        dynamicLinkURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLearnTab = /* GraphQL */ `
  query GetLearnTab($id: ID!) {
    getLearnTab(id: $id) {
      id
      cardNumber
      imageUrl
      createdAt
      updatedAt
    }
  }
`;
export const listLearnTabs = /* GraphQL */ `
  query ListLearnTabs(
    $filter: ModelLearnTabFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearnTabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cardNumber
        imageUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewsTab = /* GraphQL */ `
  query GetNewsTab($id: ID!) {
    getNewsTab(id: $id) {
      id
      url
      imageSmallUrl
      photoSmallS3 {
        bucket
        region
        key
      }
      imageLargeUrl
      photoLargeS3 {
        bucket
        region
        key
      }
      videoUrl
      video
      headerNews
      date
      title
      source
      assetsTags {
        title
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNewsTabs = /* GraphQL */ `
  query ListNewsTabs(
    $filter: ModelNewsTabFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsTabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        imageSmallUrl
        photoSmallS3 {
          bucket
          region
          key
        }
        imageLargeUrl
        photoLargeS3 {
          bucket
          region
          key
        }
        videoUrl
        video
        headerNews
        date
        title
        source
        assetsTags {
          title
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHouse = /* GraphQL */ `
  query GetHouse($id: ID!) {
    getHouse(id: $id) {
      id
      createdAt
      updatedAt
      photo
      photoS3 {
        bucket
        region
        key
      }
      fileS3 {
        bucket
        region
        key
      }
      Low1Y
      State
      city
      recom
      title
      place
      symbolID
      description
      ShareAvailable
      newBuilding
      Address
      price
      priceLive
      Net
      longitude
      PrimaryOfferID
      SecondaryOfferID
      IPOENDDATE
      NextTrading
      cityAddress
      High1Y
      commodity
      vol
      growth
      trend
      onboardFA
      onboardRE
      onboardMA
      onboardENG
      LastPriceUpdate
      HouseStatus
      RentDiv
      NextDiv
      numberOfInvstors
      mercuryAccountID
      TradingWindow
      ulLink
      latitude
      earlyAccess
      haltTrading
      CAP
      fullprice
      News {
        id
        url
        imageUrl
        date
        title
        source
      }
      Neighborhood {
        PricePerSqft
        AverageSale
        Name
        CommuteRate
        SchoolRate
        JobRate
        WalkRate
        WeatherRate
        Description
        MonthlyRent
        NGRate
        jobGrowth
        Unemployment
        RentGrowth
        DaysInMarket
        PopulationGrowth
      }
      HouseChart {
        y
        x
      }
      ShareChartTicks {
        y
        x
      }
      OpenChartTicks {
        y
        x
      }
      Documents {
        Doc1
        Doc2
        Doc3
        Doc4
        Doc5
      }
      Details {
        Type
        Lot
        Heating
        YearB
        Bathroom
        Parking
        Cooling
        YearRenov
        Bedroom
        House
      }
      ShareChart {
        GainLable
        GainTrend
        Gain
        Rent
        RentP
        GainP
      }
      nextRentPerShare
      nextRentDate
      photos
      photosS3 {
        bucket
        region
        key
      }
      Highlights {
        text
        title
        id
      }
      Financials {
        ProfitAnnual
        PricePsqft
        ExpencesAnnual
        LoanValue
        Bonus
        closing
        Renovation
        Brokerage
        RentAnnual
        NumShares
        DebtsAnnual
        LoanTovalue
        LoanInterstRate
        CashReserve
      }
    }
  }
`;
export const listHouses = /* GraphQL */ `
  query ListHouses(
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findBySymbolID = /* GraphQL */ `
  query FindBySymbolID(
    $symbolID: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findBySymbolID(
      symbolID: $symbolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findByWindow = /* GraphQL */ `
  query FindByWindow(
    $NextTrading: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findByWindow(
      NextTrading: $NextTrading
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findonboardFA = /* GraphQL */ `
  query FindonboardFA(
    $onboardFA: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findonboardFA(
      onboardFA: $onboardFA
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findonboardMA = /* GraphQL */ `
  query FindonboardMA(
    $onboardMA: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findonboardMA(
      onboardMA: $onboardMA
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findonboardRE = /* GraphQL */ `
  query FindonboardRE(
    $onboardRE: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findonboardRE(
      onboardRE: $onboardRE
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findonboardENG = /* GraphQL */ `
  query FindonboardENG(
    $onboardENG: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findonboardENG(
      onboardENG: $onboardENG
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findTitle = /* GraphQL */ `
  query FindTitle(
    $title: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findTitle(
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const findState = /* GraphQL */ `
  query FindState(
    $State: String
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findState(
      State: $State
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        photo
        photoS3 {
          bucket
          region
          key
        }
        fileS3 {
          bucket
          region
          key
        }
        Low1Y
        State
        city
        recom
        title
        place
        symbolID
        description
        ShareAvailable
        newBuilding
        Address
        price
        priceLive
        Net
        longitude
        PrimaryOfferID
        SecondaryOfferID
        IPOENDDATE
        NextTrading
        cityAddress
        High1Y
        commodity
        vol
        growth
        trend
        onboardFA
        onboardRE
        onboardMA
        onboardENG
        LastPriceUpdate
        HouseStatus
        RentDiv
        NextDiv
        numberOfInvstors
        mercuryAccountID
        TradingWindow
        ulLink
        latitude
        earlyAccess
        haltTrading
        CAP
        fullprice
        News {
          id
          url
          imageUrl
          date
          title
          source
        }
        Neighborhood {
          PricePerSqft
          AverageSale
          Name
          CommuteRate
          SchoolRate
          JobRate
          WalkRate
          WeatherRate
          Description
          MonthlyRent
          NGRate
          jobGrowth
          Unemployment
          RentGrowth
          DaysInMarket
          PopulationGrowth
        }
        HouseChart {
          y
          x
        }
        ShareChartTicks {
          y
          x
        }
        OpenChartTicks {
          y
          x
        }
        Documents {
          Doc1
          Doc2
          Doc3
          Doc4
          Doc5
        }
        Details {
          Type
          Lot
          Heating
          YearB
          Bathroom
          Parking
          Cooling
          YearRenov
          Bedroom
          House
        }
        ShareChart {
          GainLable
          GainTrend
          Gain
          Rent
          RentP
          GainP
        }
        nextRentPerShare
        nextRentDate
        photos
        photosS3 {
          bucket
          region
          key
        }
        Highlights {
          text
          title
          id
        }
        Financials {
          ProfitAnnual
          PricePsqft
          ExpencesAnnual
          LoanValue
          Bonus
          closing
          Renovation
          Brokerage
          RentAnnual
          NumShares
          DebtsAnnual
          LoanTovalue
          LoanInterstRate
          CashReserve
        }
        OpenTradingOrders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSubsList = /* GraphQL */ `
  query GetSubsList($userID: String!, $listID: String!) {
    getSubsList(userID: $userID, listID: $listID) {
      id
      listID
      userID
      subscription
      createdAt
      updatedAt
      user {
        email
        sub
        username
        firstName
        notification
        enotification
        theme
        addressCity
        faceID
        addressStreet
        streetAddress
        unitAddress
        createdAt
        updatedAt
        city
        state
        zipcode
        country
        phoneNumber
        displayName
        AvatarKey
        AvatarURL
        ChatToken
        lastName
        appTheme
        badgeCount
        updateType
        AtsUserId
        ChatGuide
        AtsApplicationId
        AtsAccountId
        AtsBankAccountUID
        LegalFirstName
        LegalLastName
        GiftStatus
        InvestmentLimit
        cash {
          amount
          power
          withdraw
          reserved
          transit
          primary
        }
        tutorial1
        tutorial2
        tutorial12
        verification
        verificationProgress
        usertype
        type
        notificationBadge
        notify
        availableInvitations
        acceptedInvites
        pendingInvites
        expiredInvites
        watchList
        mercuryID
        invitedby
        bankConnected
        tokens {
          nextToken
        }
        ownerships {
          nextToken
        }
        PlaidToken {
          nextToken
        }
        contactlist {
          nextToken
        }
        InitOrders {
          nextToken
        }
        DividendPayments {
          nextToken
        }
        activities {
          nextToken
        }
        OpenTradingOrders {
          nextToken
        }
        TradingOrders {
          nextToken
        }
        Transactions {
          nextToken
        }
        SubsLists {
          nextToken
        }
      }
    }
  }
`;
export const listSubsLists = /* GraphQL */ `
  query ListSubsLists(
    $userID: String
    $listID: ModelStringKeyConditionInput
    $filter: ModelSubsListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubsLists(
      userID: $userID
      listID: $listID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        listID
        userID
        subscription
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
export const bySubs = /* GraphQL */ `
  query BySubs(
    $listID: String
    $subscription: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySubs(
      listID: $listID
      subscription: $subscription
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listID
        userID
        subscription
        createdAt
        updatedAt
        user {
          email
          sub
          username
          firstName
          notification
          enotification
          theme
          addressCity
          faceID
          addressStreet
          streetAddress
          unitAddress
          createdAt
          updatedAt
          city
          state
          zipcode
          country
          phoneNumber
          displayName
          AvatarKey
          AvatarURL
          ChatToken
          lastName
          appTheme
          badgeCount
          updateType
          AtsUserId
          ChatGuide
          AtsApplicationId
          AtsAccountId
          AtsBankAccountUID
          LegalFirstName
          LegalLastName
          GiftStatus
          InvestmentLimit
          tutorial1
          tutorial2
          tutorial12
          verification
          verificationProgress
          usertype
          type
          notificationBadge
          notify
          availableInvitations
          acceptedInvites
          pendingInvites
          expiredInvites
          watchList
          mercuryID
          invitedby
          bankConnected
        }
      }
      nextToken
    }
  }
`;
